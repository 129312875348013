import { createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';
import apiKey from '../../../utils/axiosInstance';
import { serialize } from 'object-to-formdata';
// static dentist
export const getDentist = createAsyncThunk('dentist/getDentist', async ({ search, limit, page }, ThunkApi) => {
    try {
        const query = queryString.stringify(!search ? { limit, page } : { search, limit, page });
        const response = await apiKey.get(`/dentists?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const createDentist = createAsyncThunk('dentist/createDentist', async ({ params }, ThunkApi) => {
    try {
        const response = await apiKey.post(`/dentists`, params);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const deleteDentist = createAsyncThunk('dentist/deleteDentist', async ({ id }, ThunkApi) => {
    try {
        return await apiKey.delete(`/dentists/${id}`);
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const UpdateManyDentist = createAsyncThunk('dentist/UpdateManyDentist', async ({ dentists, cabinetId }, ThunkApi) => {
    try {
        return await apiKey.put(`/dentists/${cabinetId}`, { dentists });
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getUsersDentists = createAsyncThunk('dentist/getUsersDentists', async ({ search, limit, page, startDate, endDate, cabinet, }, ThunkApi) => {
    try {
        const query = queryString.stringify(!search
            ? { limit, page, startDate, endDate, cabinet }
            : startDate && endDate
                ? { search, limit, page, startDate, endDate, cabinet }
                : { search, limit, page, cabinet });
        const response = await apiKey.get(`/dentists?${query}`);
        const data = response.data.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const createUserDentist = createAsyncThunk('dentist/createUserDentist', async ({ params }, ThunkApi) => {
    const { file, ...otherData } = params;
    try {
        const response = await apiKey.post(`/dentists`, serialize(file ? { ...params } : { ...otherData }));
        const data = response.data.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const updateUserDentist = createAsyncThunk('dentist/updateUserDentist', async ({ params, id }, ThunkApi) => {
    try {
        const response = await apiKey.put(`/dentists/${id}`, serialize({ ...params }));
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const deleteUserDentist = createAsyncThunk('dentist/deleteProthesist', async ({ id }, ThunkApi) => {
    try {
        const response = await apiKey.delete(`/dentists/${id}`);
        const data = response.data.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
